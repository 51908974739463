var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TForm", {
        ref: "refFrom1",
        attrs: {
          lableRow: "",
          autoCheck: true,
          model: _vm.model,
          formlist: _vm.formlist,
          rowGutter: 16,
          labelPosition: "left",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }