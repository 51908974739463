<template>
	<!--定时节点配置项-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="numExample" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<div>
					<el-row>
					  	<el-col :span="12">
					  		<el-input v-model="htmlDatas[scope.current.keys].nums"  oninput="value=value.replace(/[^\d^\.]+/g,'')" />
					  	</el-col>
					  	<el-col :span="12">
					  		<el-select v-model="htmlDatas[scope.current.keys].selectype" placeholder="请选择">
							    <el-option v-for="item in scope.current.options" :key="item.code" :label="item.name" :value="item.code" />
						  	</el-select>
					  	</el-col>
					</el-row>
				</div>
			</template>
			<template slot="setFormkeys" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<el-button type="primary" size=' mini' >前往设置</el-button>
			</template>
		</TForm>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		components: { TForm },
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
					})
					
				}
			},
			'htmlDatas.timeingType': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.hideOrShowItems();
					
				}
			},
		},
		computed: {
			
			
			
			
		},
		data() {
			return {
				htmlDatas: {
					timeingType:'1',
				},
				formlist: [
					{name: '节点key',keys: 'nodeCode',value: '',type: 'input',flex:24,isMust:true},
					{name: '定时方式',keys: 'timeingType',value: '1',type: 'select',flex:24,isMust:true,options:[
	  					{name:'指定日期',code:'1'},
	  					{name:'等待日期',code:'2'},
	  				]},
	  				{name: '选择日期',keys: 'kkkk1',value:null,type: 'time',flex:24,isHide:false},
	  				{name: '节点描述',keys: 'kkkk2',value: '',type: 'textarea',flex:24,isHide:false},
	  				{name: '等待时长',keys: 'kkkk3',value: '1',type: 'select',flex:24,options:[
	  					{name:'30分钟',code:'1'},
	  					{name:'60分钟',code:'2'},
						{name:'24小时',code:'3'},
	  				],isHide:true},
	  				{name: '表达式',keys: 'kkkk4',value: '',type: 'input',flex:24,isHide:true},
				],
			};
		},
		created() {
			console.log('接收.formInfo',JSON.parse(JSON.stringify(this.formInfo)))
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			hideOrShowItems(){
				//通过定时方式判断当前列是否显示
				let keyArr1=['kkkk1','kkkk2'],keyArr2=['kkkk3','kkkk4'];
				let showIts=[],hideIts=[];
				if(this.htmlDatas.timeingType=='1'){
					showIts=keyArr1;
					hideIts=keyArr2;
				}else{
					showIts=keyArr2;
					hideIts=keyArr1;
				}
				for(let it of this.formlist){
					if(hideIts.indexOf(it.keys)>-1){
						it.isHide=true;
					}
					if(showIts.indexOf(it.keys)>-1){
						it.isHide=false;
					}
				}
				console.log('hideOrShowItems',JSON.parse(JSON.stringify(this.formlist)))
			},
			resetTFrom(){
//				let bascArr=JSON.parse(JSON.stringify(this.utilArrs));
//				console.log('sssssssss',bascArr)
//				if(this.htmlDatas.timeingType=='1'){
//					this.formlist=bascArr.concat(JSON.parse(JSON.stringify(this.waitDate)));
//				}else{
//					this.formlist=bascArr.concat(JSON.parse(JSON.stringify(this.checkDate)));
//				}
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  			
	  			console.log('sssssssss',this.formlist)
	  		},
		}
		
	};
</script>

<style scoped>
	
</style>