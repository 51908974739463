<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' title="添加参数" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="30%" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot='operating' slot-scope="s">
						<el-button type="text" >编辑</el-button>
						<el-button type="text" >删除</el-button>
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			datas: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{},
	  			isHttping:false,
	  			formlist:[
	  				{name: '参数名称',keys: 'paramName',value: '',type: 'input',trigger: ["blur", "change"]},
	  				{name: '参数类型',keys: 'paramType',value:'STRING',type: 'select',notClear:true,options:[
	  					{name:'请选择',code:''},
	  					{name:'字符串',code:'STRING'},
	  					{name:'表达式',code:'EXPRESSION'},
	  				],changMethod:this.changeSetName},
	  				{name: '值',keys: 'paramVal',value: '',type: 'input',trigger: ["blur", "change"]},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			console.log('1111paramer接收datas',JSON.parse(JSON.stringify(this.datas)))
			this.getDatasById();
		},
		mounted() {
			this.$nextTick(()=>{
			})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		changeSetName(val,it){
				//因为select选择的时候前端需要用到对应的name属性
				let _key=it.keys+'Str';
				let _val;
				it.options.map(it=>{
					if(val==it.code) _val=it.name;
				})
				if(!_val) return;
				this.$set(this.htmlDatas, _key, _val);
			},
			async getDatasById(){
				
				if(this.datas.webId){
					this.htmlDatas=JSON.parse(JSON.stringify(this.datas));
					
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
				
				
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				console.log(this.datas)
				let mId=this.datas.webId;
				if(!mId){
					mId=this.common.creadWebId();
				}
				let params={
					webId:mId,
					...this.htmlDatas,
				}
				this.$emit('close',params)
				this.$refs.htmlFrom.resetFields();
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>