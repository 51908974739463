import wgImg from '../images/wg.svg';
import timing from '../images/timing.png';

// 自定义元素的类型，此时我们只需要自定义一种节点，所以数组只有一个元素
const customElements = ['bpmn:ychWg','bpmn:IntermediateCatchEvent']; 
const customConfig = {
  // 自定义元素的配置
  wgImg: {
    'url': timing,
    'attr': {x: 0, y: 0, width: 40, height: 40,id:'ychId'}
  }
};
 
export {customElements, customConfig};