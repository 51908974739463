<template>
	<!--审核节点配置项-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="numExample" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<div>
					<el-row :gutter="1">
					  	<el-col :span="14">
					  		<el-input v-model="htmlDatas[scope.current.keys].nums"  oninput="value=value.replace(/[^\d^\.]+/g,'')" />
					  	</el-col>
					  	<el-col :span="10">
					  		<el-select v-model="htmlDatas[scope.current.keys].selectype" placeholder="请选择">
							    <el-option v-for="item in scope.current.options" :key="item.code" :label="item.name" :value="item.code" />
						  	</el-select>
					  	</el-col>
					</el-row>
				</div>
				
			</template>
			<template slot="setFormkeys" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<el-button type="primary" size='mini' @click='openFormKeyWords'>前往设置</el-button>
				
			</template>
			
		</TForm>
		<fromWordsSet v-if='openWordsSet' :isOpen='openWordsSet' :nodeArrs='bpmnNodes' :datas='htmlDatas.keyWordDatas' @close='closeDialog'  />
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import fromWordsSet from './fromWordsSet.vue';//表单字段设置弹窗
	export default {
		components: { TForm ,fromWordsSet},
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
					})
					
				}
			},
		},
		computed: {},
		data() {
			return {
				htmlDatas: {},
				formlist: [
//					{name: '节点名称',keys: 'nodeName',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点key',keys: 'nodeCode',value: '',type: 'input',flex:24,isMust:true},
					{name: '超时提醒',keys: 'dueOpen',value: 'N',type: 'switch',flex:24,isMust:true},
					{name: '超时设置[小时]',keys: 'dueTime',value: '',type: 'input',flex:24,isMust:true},
					{name: '提醒方式',keys: 'noticeMethod',value: ['1'],type: 'check',flex:24,isMust:true,options:[
//	  					{name:'待办',code:'0'},
						{name:'消息',code:'1'},
						{name:'短信',code:'2'},
						{name:'邮件',code:'3'},
	  				]},
	  				{name: '拒绝示例数量',keys: 'key555',value:{nums:10,selectype:'1'},type:'slot',slotName:"numExample",options:[
	  					{name:'数量',code:'1'},
	  					{name:'百分比',code:'2'},
	  				]},
	  				{name: '同意示例数量',keys: 'key6666',value:{nums:2,selectype:'1'},type:'slot',slotName:"numExample",options:[
	  					{name:'数量',code:'1'},
	  					{name:'百分比',code:'2'},
	  				]},
	  				{name: '表单字段设置',keys: 'keyWordDatas',value:{
	  					checkedNode:'',
	  					isDefault:false,
	  				},type:'slot',slotName:"setFormkeys"},
				],
				openWordsSet:false,
				bpmnNodes:[],
			};
		},
		created() {
			console.log('接收.formInfo',JSON.parse(JSON.stringify(this.formInfo)))
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			closeDialog(val){
				console.log('closeDialog',val)
				this.openWordsSet=false;
				if(val){
					this.htmlDatas.keyWordDatas=val;
				}
			},
			async openFormKeyWords(){
				let res =await this.$parent.getNowNodes();
				if(res){
					console.log('获取当前所有节点',res)
					this.bpmnNodes=res.nodes;
					this.openWordsSet=true;
				}
//				
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  			console.log('赋值后',JSON.parse(JSON.stringify(this.htmlDatas)))
	  		},
		}
		
	};
</script>

<style scoped>
	
</style>