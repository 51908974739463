<template>
	<!--flow整体配置-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="model" :formlist="formlist" :rowGutter='16' labelPosition='left'>
		</TForm>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		components: { TForm },
		props: {
			model:{ //表单数据对象
	            type:Object,
	            default:()=>{
	                return {}
	            },
	        },
			type: {
				type: String,
				default: '1',
			},
			
		},
		watch:{
			type: {
				deep: true, // 深度监听
				handler(val, oval) {}
			},
			'model.dueOpen': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.showHideItems(val);
					
				}
			},
		},
		computed: {},
		data() {
			return {
				htmlDatas: {},
				formlist: [
					{name: '超时提醒',keys: 'dueOpen',value: 'Y',type: 'switch',flex:24,isMust:true},
					{name: '到期时间类型',keys: 'dueType',value: 'PH',type: 'select',diyClass:'hideRow',options:[
						//到期时间类型 （PH:小时，PD:天，PW:周，PM: 月）  当前需求隐藏
						{name:'小时',code:'PH'},
					]},
					{name: '超时设置',keys: 'dueTime',value: '',type: 'input',flex:24,isMust:true,tips:'小时',diyClass:'setTimeinp',isHide:false},
					{name: '提醒方式',keys: 'notifyMethod',value: ['1'],type: 'check',flex:24,isMust:true,isHide:false,options:[
	  					// 通知方式(0:待办、1：消息、2：短信、3邮件)
//	  					{name:'待办',code:'0'},
						{name:'消息',code:'1'},
						{name:'短信',code:'2'},
						{name:'邮件',code:'3'},
	  				]},
	  				{name: '相邻节点审批人相同时',keys: 'sameApproverOperation',value: '1',type: 'checkD',isMust:true,isHide:false,options:[
						{name:'直接通过',code:'PASS'},
						{name:'顺序审批',code:'APPROVE'}
					]},
				],
			};
		},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			showHideItems(val){
				let hide=false;
				if(val=='N'){
					hide=true;
				}
				console.log('hide',hide)
				this.formlist.map(it=>{
					if(it.name!=='超时提醒') it.isHide=hide;
				})
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					
					if(!this.model[item.keys]){
						this.$set(this.model, item.keys, item.value);
					}
				}
	  			this.showHideItems(this.model.aaa1);
	  		},
		}
		
	};
</script>

<style>
	.setTimeinp{
		position: relative;
		.el-input__inner{
			width: calc(100% - 30px);
		}
		.tipTx{
			color: #252D3D;
	        position: absolute;
	        left: 90%;
	        top: 44px;
	        width: 30px;
		}
	}
	.hideRow{
		display: none;
	}
</style>