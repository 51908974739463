<template>
	<!--抄送节点配置项-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="copyTarget" slot-scope="scope">
				<div class="diyNdItems">
					<div class="cpNodeAdd">
						<el-button class='btnAdd' type="text" @click='openAddModal=true'> 
							<span class="btntxt">添加抄送</span><img src="@/assets/images/addicon2x.png" class="iconAdd" />
						</el-button>
					</div>
					<div>
						<div class="itdiv" v-for="(it,dex) in htmlDatas[scope.current.keys]" :key='dex'>
							{{it.name}} <img @click="delCopytoItms(dex)" src="@/assets/images/delx.png" class="delIcon" />
						</div>
						<div class="nullDiv" v-if="htmlDatas[scope.current.keys].length<1">
							<div ><img src="@/assets/images/tbnull.png" /></div>
							暂无数据
						</div>
					</div>
				</div>
			</template>
		</TForm>
		
		
		<copyNodeAdd v-if='openAddModal' :isOpen='openAddModal'  @close='closeDialog'  />

	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import copyNodeAdd from './copyNodeAdd.vue';
	export default {
		components: { TForm ,copyNodeAdd},
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			type: {
				deep: true, // 深度监听
				handler(val, oval) {}
			},
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
					})
					
				}
			},
		},
		computed: {},
		data() {
			return {
				htmlDatas: {},
				formlist: [
//					{name: '节点名称',keys: 'aaa1',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点key',keys: 'aaa2',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点描述',keys: 'remark',value: '',type: 'textarea',flex:24},
	  				{name: '抄送对象',keys: 'copyItems',value:[],type:'slot',slotName:"copyTarget",flex:24,isMust:true},
				],
				personArr:[
					{name:'工程部'},
					{name:'锦绣科学园'},
				],
				openAddModal:false,
				
			};
		},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		closeDialog(val){
	  			this.openAddModal=false;
	  			console.log('sdsd',val)
	  			
	  			if(val){
	  				this.htmlDatas.copyItems.push(val)
	  			}
	  		},
	  		delCopytoItms(i){
	  			this.htmlDatas.copyItems.splice(i,1);
	  		},
		}
		
	};
</script>

<style scoped>
	.cpNodeAdd{
		.nametx{
			font-weight: bold;
			font-size: 14px;
			color: #252D3D;
		}
		.btnAdd{
			position: absolute;
			top: 8px;
			padding: 0;
			right: 0;
			.btntxt{
				color: #2A61FF;
				margin-right: 4px;
			}
			.iconAdd{
				height: 14px;
				width: 14px;
			    vertical-align: top;
			}
		}
	}
	.itdiv{
		display: inline-block;
		padding: 0 12px;
		line-height: 34px;
		background: #F7F8FA;
		color: #000000;
		border-radius: 4px 4px 4px 4px;
		font-size: 13px;
		font-weight: bold;
		border: 1px solid #E9EEF9;
		position: relative;
		margin-right: 12px;
		margin-bottom: 10px;
		.delIcon{
			width: 14px;
			margin-left: 14px;
			position: relative;
			top: 2px;
			cursor: pointer;
		}
	}
	.nullDiv{
		text-align: center;
		color: #ccc;
		font-size: 12px;
		img{
			width: 84px;
		}
	}
</style>