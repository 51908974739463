<template>
	<div class="flowHtml">
		<div class="header-line">
			<div class="header-left">
				<div class="back-div" @click="$router.go(-1);">
					<img src="@/assets/images/go_back_icon.png" alt="" /><span>返回</span>
				</div>
				<div class="border-line"></div>
				<div class="name-div">流程配置</div>
			</div>
			<div class="header-right">
				<div class="look-json" >
					<img src="@/assets/images/dynamicForm/look_json.png" alt="" />
					<div class="label-line">查看json代码</div>
				</div>
				<r-button size='small' @click="clearBpmnAll">清空</r-button>
				<r-button plain @click="saveBpmn">保存</r-button>
				
			</div>
		</div>
		<mainBox class='Y-bpmn'>
			<div class="lfBox">
				<div class="containers" ref="content">
					<div class="bpmnCanvas" ref="canvas" id="canvas"></div>
					<!-- 工具栏显示的地方 -->
					<!--<div id="js-properties-panel" class="panel" ></div>-->
				</div>
			</div>
			<div class="rtBox">
				<flowConfig v-if="bpmnModeler" ref='rtPanel' :modeler="bpmnModeler"  @saveWebData='saveBpmn'/>
			</div>
		</mainBox>

	</div>

</template>

<script>
	import BpmnModeler from 'bpmn-js/lib/Modeler'
	import defaultXmlStr from './xmlStr.js'
	import customTranslate from './customTranslate.js';
	const customTranslateModule = {// 自定义汉化模块
		translate: ['value', customTranslate],
	}
	import flowConfig from "./right-flowConfig.vue";// 右侧自定义属性面板
	import customModule from './customPalette';//自定义左侧节点
	import XmlToJson from './jsonXml.js';
	export default {
		components: {flowConfig},
		data() {
			return {
				bpmnModeler: null,
				container: null,
				canvas: null,
				uploadBpmnFileList: [],
				scale: 1,
            	uuId: '',
            	currentElement:null,
			}
		},
		created() {},
		mounted() {
			this.initHtml()
		},
		methods: {
			
			/**初始化流程设计器对象*/
			async initHtml() {
				this.$nextTick(() => {
					this.initBpmn()
				})
			},
			async initBpmn() {
				// 创建BpmnModeler
				this.bpmnModeler = new BpmnModeler({
					container: this.$refs.canvas,
					propertiesPanel: {
						parent: '#js-properties-panel',// 加入工具栏支持
					},
					additionalModules: [
//						propertiesPanelModule,// 右边的工具栏 开启的时候 左侧工具栏必须要开启否则报错
//						propertiesProviderModule,// 左边工具栏以及节点
						customTranslateModule,// 汉化
						customModule,
					],
//					moddleExtensions: {
//					    //如果要在属性面板中维护camunda：XXX属性，则需要此 
//				    	camunda: camundaModdleDescriptor,
////				    	bpmn: bpmnModdleDescriptor,
//				  	},
				})
				// 创建新流程
				await this.createNewDiagram();
				this.initNodesEvents();
			},
			async createNewDiagram() {
				// 加载xml字符串转换成图显示出来;
				let lcXml = localStorage.getItem('xmlDatas');
				let bpmn;
				if(lcXml){
					bpmn=lcXml;
				}else{
					bpmn=defaultXmlStr;
				}
				this.bpmnModeler.importXML(bpmn, err => {
					if(err) {
						this.$message.error('打开模型出错,请确认该模型符合Bpmn2.0规范')
					} else {
						console.log('成功导入模型')
//					 	bpmnModeler.getJSON({ format: true }, function(err, json) {});//无效方法 
					}
				})
				this.$nextTick(()=>{
//					this.bpmnModeler.get('canvas').zoom(0.1,'auto');
					setTimeout(()=>{
//						this.bpmnModeler.get('canvas').zoom(0.5,'auto');
						this.bpmnModeler.get('canvas').zoom("fit-viewport",'auto');//渲染成功的话 视图剧中
					},200)
					
					
				})
				
				
			},
			initNodesEvents(){
				const eventBus = this.bpmnModeler.get('eventBus');
				// 注册节点事件，eventTypes中可以写多个事件
				const eventTypes = ['element.click', 'element.hover'];
				eventTypes.forEach((eventType) => {
				  eventBus.on(eventType, (e) => {
				    const {element} = e;
				    if (!element.parent) return;
				    if (!e || element.type === 'bpmn:Process') {
				      return false;
				    } else {
				      if (eventType === 'element.click') {
				        // 节点点击后想要做的处理
				        // 此时想要点击节点后，拿到节点实例，通过外部输入更新节点名称
				        this.currentElement = element;
				        console.log('鼠标d点击啦~',element);
         	 			this.splitBusiness2Json(element.businessObject || element);
				      } else if (eventType === 'element.hover') {
				        // 鼠标滑过节点后想要做的处理
//				        console.log('鼠标经过节点啦~');
				      }
				    }
				  });
				});
				
			},
			
			editNodeInfo(){
				//通过 modeling 的 updateProperties 方法可以修改节点属性，例如这里修改节点 name 为 ops-coffee.cn
//				const modeling = this.bpmnModeler.get("modeling");
//				const element =this.bpmnModeler.get("elementRegistry").get("Activity_lwlvj9r");
//				modeling.updateProperties(element，{name:"ops-coffee.cn"});
			},
			
			
			
			
        getExtensionElement(element, type) {
		  if (!element.extensionElements) {
		    return;
		  }
		  return element.extensionElements.values.filter((extensionElement) => {
		    return extensionElement.$instanceOf(type);
		  })[0];
		},
        splitBusiness2Json(businessObject) {
		  let formData = {};
		  let params = this.getExtensionElement(businessObject, 'bpmn:UserTask');
		  if (params && params.inputParameters) {
		    params.inputParameters.forEach((item) => {
		      let definition = item.definition;
		      if (definition) {
		        if (definition.$type === 'camunda:List') {
		          let arr = [];
		          definition.items.forEach((itemsItem) => {
		            arr.push(itemsItem.value);
		          });
		          formData[item.name] = arr;
		        } else if (definition.$type === 'camunda:Map') {
		          let obj = {};
		          if (definition.entries) {
		            definition.entries.forEach((entriesItem) => {
		              obj[entriesItem.key] = entriesItem.value;
		            });
		            formData[item.name] = obj;
		          }
		        }
		      } else {
		        formData[item.name] = item.value;
		      }
		    });
		  }
//		  console.log('formData.formData', formData);
		},
			

			clearBpmnAll(){
				let nullXml='<?xml version="1.0" encoding="UTF-8"?><definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:flowable="http://flowable.org/bpmn" targetNamespace="http://flowable.org/bpmn"><process id="xxx" name="xxxx" isExecutable="true" /><bpmndi:BPMNDiagram id="BPMNDiagram_xxx"><bpmndi:BPMNPlane id="BPMNPlane_xxx" bpmnElement="xxx" /></bpmndi:BPMNDiagram></definitions>';
				this.bpmnModeler.importXML(nullXml, err => {})
				this.bpmnModeler.get('canvas').zoom("fit-viewport", "auto");//渲染成功的话 视图剧中
			},
			beforeUpload(file) {
			},
			
			nowBpmnNodeToDatas(){
				//获取当前视图中所有节点并且转成数据
				return new Promise((resolve, reject) => {
					this.bpmnModeler.saveXML({ format: true }, function(err, xml) {
						const xotree = new XmlToJson.ObjTree();
			      		const jsonData = xotree.parseXML(xml);
				      	if(jsonData&&jsonData.definitions){
				      		const {process}=jsonData.definitions;
				      		const {startEvent,endEvent,sequenceFlow}=process;
				      		let nodeArrs=[];
				      		let notAlow=['-id','-name','-isExecutable','startEvent','endEvent','sequenceFlow'];//需要过滤的数据
				      		for(let k in process){
				      			if(notAlow.indexOf(k)<0){//进行比对过滤
				      				let kvals=process[k];//通过key取对应的值
					      			if(kvals){
					      				let isMore=Array.isArray(kvals); //判断对应(如task)类型节点 是否为多个
						      			if(isMore){
						      				kvals.map(it=>{
						      					it.nodeType=k
						      				});
						      				nodeArrs=nodeArrs.concat(kvals);//如果是多个的话 直接相加
						      			}else{
						      				kvals.nodeType=k;
						      				nodeArrs.push(kvals);
						      			}
				      				}
				      			}
				      		}
				      		nodeArrs.push({...endEvent,nodeType:'endNode'});//添加结束节点
				      		//处理出后台需要的数据格式
				      		nodeArrs.map(it=>{//处理单一流程节点的数据
				      			it.name=it['-name'];
				      			it.nodeId=it['-id'];
				      		})
				      		sequenceFlow.map(it=>{
				      			it.lineId=it['-id'];
				      			it.fromNodeId=it['-sourceRef'];//起始节点ID 对应 sourceRef
				      			it.toNodeId=it['-targetRef'];//目标节点ID 对应 targetRef
				      		})
				      		resolve({
				      			nodes:nodeArrs,//节点
				      			lines:sequenceFlow,//线
				      		});
				      	}
			      	})
				});
			},
			
			async saveBpmn() {
			 	this.getEleNodesTypes()
			 	const that=this;
				this.bpmnModeler.saveXML({ format: true }, function(err, xml) {
			       	//无效方法1
//			       	const BpmnModdle = require('bpmn-js/lib/Modeler');
//					const xmlJsonModdle = new BpmnModdle();
//					xmlJsonModdle.fromXML(xml, 'definitions.json', (err, definitions, context) => {
//					  if (err) {
//					    console.error('解析错误:', err);
//					  } else {
//					    // 转换为JSON
//					    const json = definitions.$model.toJSON(definitions);
//					    console.log('JSON:', json);
//					  }
//					});
			       
			       
			      	const xotree = new XmlToJson.ObjTree()
		      		const jsonData = xotree.parseXML(xml) 
			      	console.log('jsonData',jsonData)
			      	if(jsonData&&jsonData.definitions){
			      		//task任务节点  userTask审核节点 startEvent开始节点 sequenceFlow连接线
			      		const {process}=jsonData.definitions;
			      		// sequenceFlow 中  -sourceRef=线起点id -targetRef线终点id 
			      		//节点类型有[ 任务节点task， userTask,数据dataStoreReferenc,文件dataObjectReference,subProcess]
			      		//网关类型有5种  [X互斥eventBasedGateway,O相容inclusiveGateway，+并行parallelGateway,*复杂网关complexGateway，事件网关eventBasedGateway]
			      		//任务类型有  [任务节点Task,用户审核userask，手工manualTask，接收receiveTask，sendTask发送，业务规范businessRuleTask，serviceTask服务，scriptTask脚本，callActivity调用，子流程subProcess]
			      		//开始事件类型（格式startEvent.types）在有[messageEventDefinition消息开始，timerEventDefinition定时开始，conditionalEventDefinition条件开始，signalEventDefinition信号开始]
			      		//中间事件类[intermediateCatchEvent消息，timerEventDefinition定时，intermediateCatchEvent条件，intermediateCatchEvent信号]
			      		const {startEvent,endEvent,sequenceFlow}=process;
			      		let nodeArrs=[];
			      		if(startEvent&&endEvent){
			      			let len1=startEvent.length;
			      			let len2=endEvent.length;
			      			if(len1||len2){
			      				that.$message.warning('存在多个开始或结束节点！');
			      				return;
			      			}else{
			      				nodeArrs.push({...startEvent,nodeType:'startNode'});//添加开始节点
			      			}
			      			
			      		}else{
			      			that.$message.warning('起始结束节点不可为空！');
			      			return;
			      		}
			      		localStorage.setItem('xmlDatas',xml.toString());
			      		let notAlow=['-id','-name','-isExecutable','startEvent','endEvent','sequenceFlow'];//需要过滤的数据
			      		for(let k in process){
			      			if(notAlow.indexOf(k)<0){//进行比对过滤
			      				let kvals=process[k];//通过key取对应的值
				      			if(kvals){
				      				let isMore=Array.isArray(kvals); //判断对应(如task)类型节点 是否为多个
					      			if(isMore){
					      				kvals.map(it=>{
					      					it.nodeType=k
					      				});
					      				nodeArrs=nodeArrs.concat(kvals);//如果是多个的话 直接相加
					      			}else{
					      				kvals.nodeType=k;
					      				nodeArrs.push(kvals);
					      			}
//				      				console.log('isMore',JSON.parse(JSON.stringify(kvals)))
			      				}
			      			}
			      		}
			      		
			      		let NodeTypeObject={
			      			startNode:'开始节点',
			      			endNode:'结束节点',
			      			userTask:'审核节点',
//							inclusiveGateway:'相容网关',
							exclusiveGateway:'并行网关',
							intermediateCatchEvent:'定时节点',
							receiveTask:'抄送',
							exclusiveGateway:'条件网关',
			      		}
			      		nodeArrs.push({...endEvent,nodeType:'endNode'});//添加结束节点
			      		
			      		//处理出后台需要的数据格式
			      		let reqData={
							"nodeType": "mode",
							"modeVO":{
								"modeId":"",// 流程模型唯一ID，新增无需此参数
								"processConfig": "", // 流程配置JSON字符串
								"listen":[],// 整体监听配置
							}
						}
			      		let flowCofigs=that.$refs.rtPanel.flowCofigs;
			      		sequenceFlow.map(it=>{
			      			it.lineId=it['-id'];
			      			it.fromNodeId=it['-sourceRef'];//起始节点ID 对应 sourceRef
			      			it.toNodeId=it['-targetRef'];//目标节点ID 对应 targetRef
			      		})
			      		nodeArrs.map(it=>{//处理单一流程节点的数据
			      			let backendObj={//后台需要的对象格式，数据重组
								"nodeId" : it['-id'], // 节点ID
								"nodeName": it['-name'], // 节点名称
								"nodeCode": "", // 节点编码
								"nodeType": "", // 节点类型 （开始节点、结束节点、条件网关、并行网关、定时节点、审批节点）
								"handlingWhenEmpty": "", // 处理人为空时（AUTO_PASS:自动通过（默认）、AUTO_REJECT:自动拒绝、ASSIGNER:指定处理人）
								"nodeComment": "", // 节点备注
								"listen": "[]", // 节点监听配置
			      			}
			      			if(it['-webDatas']){
			      				const itWebObj=JSON.parse(it['-webDatas']);
			      				
//			      				console.log(backendObj.nodeName,itWebObj)
			      				if(itWebObj.webDatas){
			      					const nodeInfo=JSON.parse(itWebObj.webDatas)
//			      					console.log(backendObj.nodeName,'nodeInfo',nodeInfo)
			      				}
			      				
			      				
			      			}
			      			
			      			
			      			if(it.nodeType=='task'||it.nodeType=='userTask'){	// 
			      				// 审批节点特有
								backendObj.auditNode= {
									// 基本配置
									"base": {
										"dueType": "", // 到期时间类型（PH:小时，PD:天，PW:周，PM: 月）
										"dueTime": "", // 到期时间值
										"operate": "", // 操作（通知：NOTIFY、转派：REASSIGN）
										"noticeMethod": "", // 通知方式（0:待办、1：消息、2：短信、3邮件)
										"transferPersonType": "", // 转派人类型（PEOPLE：指定人、DEPT:部门、POST:职位、LEADER:上下级）
										"transferPerson": "", // 转派人标识（人员 id、部门 id、职位 id）
									},
									// 会签设置
									"countersign": {
										"agreeInstanceType": "", // 同意实例类型（0：百分比，1：数值）
										"agreeInstanceVal": "", // 同意实例值
										"rejectInstanceType": "", // 同意实例类型（0：百分比，1：数值）
										"rejectInstanceCount": "", // 拒绝实例值
									},
									// 表单字段配置JSON字符串
									"formConfig": "",
									// 审核人设置
									"auditor": {
										"reviewerType": "", // 审核人类型（PEOPLE：指定人、DEPT:部门、POST:职位、LEADER:上下级）
										"reviewerIdentifier": "", // 审核人标识（人员 id、部门 id、职位 id）
									}
								}
			      			}
			      			if(it.nodeType=='intermediateThrowEvent'){	// 定时节点特有
								backendObj.timer= {
									"timerType": "", // 定时方式(DATE:指定日期、DURATION:等待时长、EXPRESSION:表达式)
									"specifiedDate": "", // 指定日期
									"waitingDuration": 15, // 等待时长(分钟)
									"expression": "", // 表达式
								}
			      			}
			      			
			      			for(let keys in backendObj){
			      				//将转换的值赋给节点上去
			      				it[keys]=backendObj[keys]
			      			}
			      		})
			      		
			      		
			      		let webFlowDatas={
			      			flowCofigs,
			      			nodes:nodeArrs,
			      			lines:sequenceFlow,
			      		}
			      		let processConfig={//流程图 节点配置
			      			webFlowDatas:webFlowDatas,//前端数据，渲染用  后台不能改变 要原封不动返回
			      			nodes:nodeArrs,//节点
			      			lines:sequenceFlow,//线
			      		}
			      		const {basics,listen}=flowCofigs;
			      		if(basics){//流程基本设置
			      			if(basics.dueOpen=='Y'){
			      				reqData.modeVO={
			      					...basics,
			      					...reqData.modeVO,
			      				}
			      			}
			      		}
			      		if(listen&&listen.webDatas){//流程监听设置
			      			const {listenConfigs}=JSON.parse(listen.webDatas);
			      			if(listenConfigs){
//			      				reqData.modeVO.listen=listenConfigs;
								processConfig.globalListen=listenConfigs;
			      			}
			      		}
			      		reqData.modeVO.processConfig=JSON.stringify(processConfig);
			      		if(reqData.modeVO.notifyMethod) reqData.modeVO.notifyMethod=reqData.modeVO.notifyMethod.join(',');
			      		localStorage.setItem('flowDatas',JSON.stringify(webFlowDatas));
			      		
			      		console.log('reqData',JSON.parse(JSON.stringify(webFlowDatas)))
//			      		console.log('reqData',JSON.parse(JSON.stringify(reqData)))
			      		
			      		that.ApiHttp('/workflow/mode/saveNode', reqData,'POST').then(res=>{
			      			
			      			
			      			
			      			console.log('reqData',res)
			      			
			      		});
			      		
//			      		console.log('webDatas',JSON.stringify(webDatas))
			      	}
//			      	let josnXml=JsonToBpmnXml(jsonData);//该方法实验过也无效
//			      	console.log('JsonToBpmnXml',JSON.parse(JSON.stringify(josnXml)))
			     
			      })
			},
			sendHttpDatas(reqData){
				
				console.log('sendHttpDatas',11111111111)
				
			},
			getEleNodesTypes(){
				//获取流程图内所有指定类型的节点
				const elementRegistry = this.bpmnModeler.get('elementRegistry');
				console.log('所有的节点ele',elementRegistry)
				console.log('所有的节点',JSON.parse(JSON.stringify(elementRegistry)))
				const userTaskList = elementRegistry.filter(
				  (item) => item.type === 'bpmn:UserTask'
				);
				console.log('userTaskList',JSON.parse(JSON.stringify(userTaskList)))
				
			},
		},
		computed: {},
	}
</script>

<style lang="scss" scoped>

.flowHtml{
	.header-line {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e3e8ee;
		background: #ffffff;
		padding: 0 40px;
		.header-left {
			display: flex;
			align-items: cneter;
			.back-div {
				cursor: pointer;
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 18px;
				color: #2a61ff;
				img {
					margin-right: 4px;
					position: relative;
					top: -1px;
				}
				span {
					font-weight: 600;
				}
			}
			.border-line {
				width: 1px;
				height: 17px;
				background: #cbdbe9;
				margin: 0 10px;
			}
			.name-div {
				font-weight: 600;
				font-size: 18px;
				color: #252d3d;
			}
		}
		.header-right {
			display: flex;
			align-items: center;
			.look-json {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				margin-right: 32px;
				.label-line {
					font-weight: 500;
					font-size: 12px;
					color: #252d3d;
					margin-top: 6px;
				}
				img {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}

.Y-bpmn{
	// 左边工具栏以及编辑节点的样式
	@import '~bpmn-js/dist/assets/diagram-js.css';
	@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
	@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
	@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
	padding: 0px;
	:deep(.m-body){
		padding: 0 !important;
	}
	.lfBox,.rtBox{
		height: 100%;
		display: inline-block;
	    vertical-align: top;
	}
	.lfBox{
		width: calc(100% - 283px);
	}
	.rtBox{
		padding: 0;
		width: 280px;
	}
	.containers {
		background-color: #red;
		width: 100%;
		height: 100%;
		background: url('../FlowBpmn/images/backg.png');
		background-size: 100%;
	}
	.bpmnCanvas {
		width: 100%;
		height: 100%;
	}
	.panel {
		position: absolute;
		right: 0;
		top: 0;
		width: 300px;
	}
}
</style>
<style lang="scss">
	.Y-bpmn {
		height: calc(100vh - 70px);
		.djs-palette.open{
			width: 48px !important;
			background: white;
			border-radius: 8px;
			border: none;
			.separator{
				/*display: none;*/
				border-bottom:1px solid #eee;
			}
			/*左侧节点栏 调节可控制换行*/
		}
		.bjs-powered-by{
			display: none;
			/*隐藏bpmn logo*/
		}
		.bpmn-icon-cake {
		  background-image: url('./images/wg.svg') !important;
		}
		.bpmn-icon-userTask{
		 	 background-image: url('./images/utask.png') !important;
		}
		.bpmn-icon-copy{
		 	background-image: url('./images/ucopy.png') !important;
		}
		.bpmn-icon-timing{
		 	background-image: url('./images/timing.png') !important;
		}
		.icon-custom {
		  background-size: 65%;
		  background-repeat: no-repeat;
		  background-position: center center;
		}
		.djs-context-pad {
			.bpmn-icon-text-annotation,.bpmn-icon-end-event-none,.bpmn-icon-intermediate-event-none{
				display: none !important;
			}
		}
		.djs-popup-body{
			.bpmn-icon-gateway-or{
				/*相容网关隐藏*/
				display: none;
			}
			.bpmn-icon-gateway-complex{
				/*隐藏复杂网关*/
				display: none;
			}
			.bpmn-icon-gateway-eventbased{
				/*隐藏事件网关*/
				display: none;
			}
			
		}
		.djs-palette-entries{
			.bpmn-icon-data-object{
				/*文件图标*/
				display: none !important;
			}
			.bpmn-icon-task{
				/*普通任务节点*/
				display: none !important;
			}
			.bpmn-icon-data-store{
				/*数据圆柱 数据存储引用*/
				display: none !important;
			}
			.bpmn-icon-participant{
				/*创建池/参与者*/
				display: none !important;
			}
			.bpmn-icon-group{
				/*创建组*/
				display: none !important;
			}
			.bpmn-icon-subprocess-expanded{
				display: none !important;
			}
			.bpmn-icon-intermediate-event-none{
				/*创建中间/边界事件*/
				display: none !important;
			}
		}
		.el-form-item__label{
			color:#252D3D ;
		}
		.djs-shape.selected{
			background: red;
		}
		.el-checkbox__input.is-checked+.el-checkbox__label,.el-radio__input.is-checked+.el-radio__label{
			    color: #2a61ff;
		}
		.el-input__inner,.el-checkbox__inner,.el-radio__inner{
			background: #F7F8FA;
		}
		.el-input__inner{
			border: none;
		}
		.el-checkbox__input.is-checked .el-checkbox__inner, 
		.el-checkbox__input.is-indeterminate .el-checkbox__inner,
		.el-radio__input.is-checked .el-radio__inner,
		.el-switch.is-checked .el-switch__core{
			background-color: #2a61ff;
   		 	border-color: #2a61ff;
		}
	}
</style>