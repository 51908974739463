<template>
	<!--flow-监听设置-->
	<div class="lsenWarp">
		<div class="tbBox ">
			<div class="flowTbAdd">
				<span class="nametx">执行监听器</span>
				<el-button class='btnAdd' type="text" @click='openDrawer'> 
					<span class="btntxt">添加</span><img src="@/assets/images/addicon2x.png" class="iconAdd" />
				</el-button>
			</div>
			<TTable2 class='lsTable'  ref="myTable1" :tbloading='tbloading' :showQuery='rowQuery' @rowSeach='rowSeach' nullIcon='84' :tableData="tableData" :tableTitle="tableTitle">
				<template slot='operating' slot-scope="s">
					<el-button type="text" @click='tbRowEdit(s.row)'>编辑</el-button>
					<el-button type="text" class='txRed' @click='tbRowDel(s.row)'>删除</el-button>
				</template>
			</TTable2>
		</div>
		<AddListen v-if='drawerObj.open' :isOpen='drawerObj.open' :datas='drawerObj.tbDatas' @close='closeDrawer'></AddListen>
	</div>
</template>

<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable from '@/components/YTable/TTable.vue';
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import TForm from '@/components/YTable/TForm.vue';
	import AddListen from './AddListen.vue';
	import AddParameters from './AddParameters.vue';
	
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TTable2,TPagination,TForm,AddParameters,AddListen},
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			
			formInfo: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.initHtml();
					})
				}
			},
			
		},
		computed: {},
		data() {
			return {
				tableTitle: [
					{name: '监听名称',prop: "listenerName",width: "80",},
					{name: '监听类型',prop: "eventTypeStr",width: "80",},
					{name: '操作',width: "80",fixed: 'right',slotName: "operating"},
				],
				tableData: [],
				drawerObj:{
					open:false,
					tbDatas:{},
				},
				parmInfo:{
					open:false,
					datas:null,
				},
				showQuery:true,
			};
		},
		created() {
		},
		mounted() {
			this.showRowQuery();
			this.initHtml()
		},
		methods: {
			initHtml(){
				//加载页面内容
				console.log('监听设置接收.formInfo',JSON.parse(JSON.stringify(this.formInfo)))
				const wbObj=this.formInfo.webDatas
				if(wbObj){
					const { listenConfigs }=JSON.parse(wbObj);
					this.tableData=listenConfigs||[];
				}
			},
			tbRowEdit(row){
				this.drawerObj={
					open:true,
					tbDatas:{...row},
				}
			},
			tbRowDel(row){
				let dex=this.tableData.indexOf(row);
				console.log(666666666,dex)
				this.tableData.splice(dex,1);
				this.emitTbDatas();
			},
			closeDrawer(val){
				this.drawerObj={
					open:false,
					tbDatas:{},
				}
				if(val){
					let findDex=-1;
					this.tableData.map((it,dex)=>{
						if(val.webId==it.webId){
							findDex=dex;
							console.log('9999999',val,findDex)
						}
					})
					if(findDex>-1){
						this.tableData[findDex]=val;
					}else{
						this.tableData.push(val);
					}
					this.emitTbDatas();
					
				}
			},
			emitTbDatas(){
				
				//将最新的数据更新绑定到flow节点上去
					const {webDatas}=this.formInfo;
					let myData={};
					if(webDatas){
						myData=JSON.parse(webDatas);
					}
					myData.listenConfigs=this.tableData;
					let dataInfo={
						...this.formInfo,
						webDatas:JSON.stringify(myData),
					}
					console.log('this.formInfo',dataInfo)
					this.$emit('upvals',dataInfo);
				
				
			},
			openDrawer(){
				this.drawerObj.open=true;
				this.$nextTick(()=>{
					this.showQuery=false;
				})
			},
			closeDialog(val){
				//关闭弹窗的时候获取子组件传过来的值
				this.parmInfo.open=false;
				if(val){
					this.tableData.push(val)
				}
				console.log('closeDialog',val)
			},
			
			async initTable() {
//				this.tableParams.total = res.total;
				this.$nextTick(() => {
					this.tbloading = false;
				})
			},
		}
		
	};
</script>

<style scoped>
	.lsenWarp{
		:deep(.el-table__cell){
			padding:0;
		}
		:deep(.cell){
			padding:2px;
		}
	}
	.drawBody{
		padding: 8px 20px;
	}
</style>
<style type="text/css">
	.flowTbAdd{
		position: relative;
		margin-bottom: 8px;
		line-height: 32px;
		.nametx{
			font-weight: bold;
			font-size: 14px;
			color: #252D3D;
		}
		.btnAdd{
			position: absolute;
			top: 8px;
			padding: 0;
			right: 0;
			.btntxt{
				color: #2A61FF;
				margin-right: 4px;
			}
			.iconAdd{
				height: 14px;
				width: 14px;
			    vertical-align: top;
			}
		}
	}
	.lsTable{
		.el-table th.el-table__cell{
			background-color:#F4F6F9;
		}
		.TTable2 .el-table__header tr th{
			height: 36px;
		}
		.TTable2 .cell{
			text-align: center;
			font-size: 12px;
		}
		.TTable2 .txRed.el-button--text{
			color: #F35555 !important;
		}
		.TTable2 .el-button--text{
			color: #2A61FF !important;
		}
	}
</style>