var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flowHtml" },
    [
      _c("div", { staticClass: "header-line" }, [
        _c("div", { staticClass: "header-left" }, [
          _c(
            "div",
            {
              staticClass: "back-div",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/go_back_icon.png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v("返回")]),
            ]
          ),
          _c("div", { staticClass: "border-line" }),
          _c("div", { staticClass: "name-div" }, [_vm._v("流程配置")]),
        ]),
        _c(
          "div",
          { staticClass: "header-right" },
          [
            _vm._m(0),
            _c(
              "r-button",
              { attrs: { size: "small" }, on: { click: _vm.clearBpmnAll } },
              [_vm._v("清空")]
            ),
            _c(
              "r-button",
              { attrs: { plain: "" }, on: { click: _vm.saveBpmn } },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _c("mainBox", { staticClass: "Y-bpmn" }, [
        _c("div", { staticClass: "lfBox" }, [
          _c("div", { ref: "content", staticClass: "containers" }, [
            _c("div", {
              ref: "canvas",
              staticClass: "bpmnCanvas",
              attrs: { id: "canvas" },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rtBox" },
          [
            _vm.bpmnModeler
              ? _c("flowConfig", {
                  ref: "rtPanel",
                  attrs: { modeler: _vm.bpmnModeler },
                  on: { saveWebData: _vm.saveBpmn },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "look-json" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/dynamicForm/look_json.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "label-line" }, [_vm._v("查看json代码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }