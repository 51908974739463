<template>
	<div>
		<div class="drawerBox">
			<el-drawer title="监听类型" :visible.sync="$attrs.isOpen"  :wrapper-closable='false' :before-close="closeMypup" :with-header="false" :size="400">
			  	<div class="drawBody">
			  		<TForm ref="htmlFrom" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
				
					</TForm>
			  		<div class="flowTbAdd">
						<span class="nametx">参数配置</span>
						<el-button class='btnAdd' type="text" @click='openModal'> 
							<span class="btntxt">添加</span><img src="@/assets/images/addicon2x.png" class="iconAdd" />
						</el-button>
						
					</div>
			  		<div class="tbDiv" :key='parmInfo.open'>
			  			<TTable2 class='lsTable' ref="myTable2"  nullIcon='84' serialNumber :tbloading='tbloading' :showQuery='rowQuery'  :tableData="tableData" :tableTitle="tableTitle">
							<template slot='operating' slot-scope="s">
								<el-button type="text" @click='tbRowEdit(s.row)'>编辑</el-button>
								<el-button type="text" class='txRed' @click='tbRowDel(s.row)'>删除</el-button>
							</template>
						</TTable2>
			  		</div>
			  		<div class="btmBtn" >
			    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
			    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
			        </div>
			  </div>
			</el-drawer>
		</div>
		
		<AddParameters v-if='parmInfo.open' :isOpen='parmInfo.open' :datas='parmInfo.tbDatas' @close='closeParmsDialog'  />
	</div>
</template>

<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import TForm from '@/components/YTable/TForm.vue';
	import AddParameters from './AddParameters.vue';
	export default {
		props: {
			datas: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		mixins: [pagination,seachAndTable],
		components: {TTable2,TPagination,TForm,AddParameters},
		data() {
			return {
				htmlDatas:{},
				tableTitle: [
					{name: '参数名称',prop: "paramName",width: "80",},
					{name: '参数类型',prop: "paramTypeStr",width: "80",},
					{name: '值',prop: "paramVal",width:'60'},
					{name: '操作',width: "80",fixed: 'right',slotName: "operating"},
				],
				tableData: [],
	  			isHttping:false,
	  			formlist:[
	  				{name: '监听名称',keys: 'listenerName',value: '',type: 'input',isMust:true},
					{name: '监听类型',keys: 'eventType',value: '',type: 'select',notClear:true,isMust:true,options:[
	  					{name:'请选择',code:''},
	  					{name:'启动',code:'STARTED'},
						{name:'执行中',code:'EXECUTE'},
						{name:'结束',code:'END'},
	  				],changMethod:this.changeSetName},
	  				{name: '请求类型',keys: 'requsetType',value: 'serviceCall',type: 'select',isMust:true,options:[
	  					{name:'服务调用(同步)',code:'serviceCall'},
						{name:'消息推送(异步)',code:'msgPush'}
	  				]},
	  				//请求类型=serviceCall服务调用展示的2条内容
	  				{name: '服务类型',keys: 'serviceType',value: '',type: 'checkD',isMust:true,options:[
						{name:'内部服务',code:'INTERNAL'},
						{name:'外部服务',code:'EXTERNAL'}
					],isHide:false},
					{name: '服务地址输入',keys: 'serviceUrl',value: '',type: 'input',isHide:false},
					//请求类型=msgPush 消息推送展示的2条内容
					{name: '消息中间件类型',keys: 'serviceCallMes',value: 'INTERNAL',type: 'checkD',isMust:true,options:[
						{name:'内部消息',code:'INTERNAL'},
						{name:'外部消息',code:'EXTERNAL'}
					],isHide:false},
					{name: '主题名称输入',keys: 'topic',value: '',type: 'input',isHide:true},
					
				],
				parmInfo:{
					open:false,
					tbDatas:{},
				},
				showQuery:true,
			}
		},
		
		computed: {},
		watch:{
			'htmlDatas.requsetType': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.hideOrShowItems(val);
				}
			},
			
		},
		created() {
			console.log('drawer接收datas',JSON.parse(JSON.stringify(this.datas)))
			this.resetTFrom();
			this.getDatasById();
		},
		mounted() {
			this.$nextTick(()=>{
				this.showRowQuery();
				
			})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			initTable(){
				
			},
			
			changeSetName(val,it){
				//因为select选择的时候前端需要用到对应的name属性
				let _key=it.keys+'Str';
				let _val;
				it.options.map(it=>{
					if(val==it.code) _val=it.name;
				})
				if(!_val) return;
				this.$set(this.htmlDatas, _key, _val);
			},
			
			tbRowEdit(row){
				this.parmInfo={
					open:true,
					tbDatas:{...row},
				}
				console.log(666666666,this.parmInfo)
			},
			tbRowDel(row){
				let dex=this.tableData.indexOf(row);
				console.log(666666666,dex)
				this.tableData.splice(dex,1);
			},
			openModal(){
				this.parmInfo={
					open:true,
					tbDatas:{},
				}
			},
			closeParmsDialog(val){
				if(val){
					let findDex=-1;
					this.tableData.map((it,dex)=>{
						if(val.webId==it.webId){
							findDex=dex;
						}
					})
					if(findDex>-1){
						this.tableData[findDex]=val;
					}else{
						this.tableData.push(val);
					}
				}
				console.log('closeDialog',val)
				this.parmInfo={
					open:false,
					tbDatas:{},
				}
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			async getDatasById(){
				
				if(this.datas.webId){
					this.htmlDatas=JSON.parse(JSON.stringify(this.datas));
					this.tableData=this.datas.paramList||[];
					
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			hideOrShowItems(val){
				//通过请求类型判断当前列是否显示
				let keyArr1=['serviceType','serviceUrl'],keyArr2=['serviceCallMes','topic'];
				let showIts=[],hideIts=[];
				if(val=='serviceCall'){
					showIts=keyArr1;
					hideIts=keyArr2;
				}else{
					showIts=keyArr2;
					hideIts=keyArr1;
				}
				for(let it of this.formlist){
					if(hideIts.indexOf(it.keys)>-1){
						it.isHide=true;
					}
					if(showIts.indexOf(it.keys)>-1){
						it.isHide=false;
					}
				}
				console.log('hideOrShowItems',JSON.parse(JSON.stringify(this.formlist)))
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				
				let mId=this.datas.webId;
				if(!mId){
					mId=this.common.creadWebId();
				}
				let params={
					...this.htmlDatas,
					paramList:this.tableData,
					webId:mId,
				}
				if(params.requsetType=='msgPush'){
					//msgPush+serviceCall 2个数据是后台要的  前端用不上  按要求处理出来
					params.msgPush={
						"topic": this.htmlDatas.topic, // 消息主题
						"serviceType": this.htmlDatas.serviceCallMes, // 服务类型（INTERNAL：内部、EXTERNAL：外部）
					}
				}
				if(params.requsetType=='serviceCall'){
					params.serviceCall ={
						"serviceUrl":this.htmlDatas.serviceUrl, // 服务类型（INTERNAL：内部、EXTERNAL：外部）
						"serviceType": this.htmlDatas.serviceType, // 服务类型（INTERNAL：内部、EXTERNAL：外部）
					}
				}
				this.$emit('close',params)
				this.$refs.htmlFrom.resetFields();
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	margin-top: 40px;
	text-align: right;
}
.drawBody{
		padding: 8px 20px;
	}

</style>
<style lang="scss">

.tbDiv{
	.el-table__row.expanded:first-child{
		display: none !important;
	}
}
</style>