export default class CustomPalette {
  constructor(create, elementFactory, palette) {
    this.create = create;
    this.elementFactory = elementFactory;
    palette.registerProvider(this);
  }
 
 
  // 这个是绘制palette的核心，函数名不要变
  getPaletteEntries() {
    const elementFactory = this.elementFactory;
    const create = this.create;
 
    function dragEventFactory(type) {
      return function (event) {
        const taskShape = elementFactory.create('shape', {
          type: type,// 内置的圆角矩形，除此之外还有 bpmn:StartEvent、bpmn:ServiceTask、bpmn:ExclusiveGateway
          name:'AAA',
        });
        create.start(event, taskShape);
      };
    }
 
    return {
       'create.userTask': {
        title: '审批节点',    // 鼠标悬浮到节点上显示的文字
        group: 'model',
        className: 'icon-custom bpmn-icon-userTask',   // 样式名
        action: {      // 操作该节点时会触发的事件，此时只注册一个拖动事件即可，否则拖动时没有效果
          dragstart: dragEventFactory('bpmn:UserTask')
        }
      },
      'create.receiveTask': {
        title: '抄送节点',    // 鼠标悬浮到节点上显示的文字
        group: 'model',
        className: 'icon-custom bpmn-icon-copy',   // 样式名
        action: {      // 操作该节点时会触发的事件，此时只注册一个拖动事件即可，否则拖动时没有效果
          dragstart: dragEventFactory('bpmn:ReceiveTask')
        }
      },
//    'create.timing': {
//      title: '定时节点',    // 鼠标悬浮到节点上显示的文字
//      group: 'model',
//      className: 'icon-custom bpmn-icon-timing',   // 样式名
//      action: {      // 操作该节点时会触发的事件，此时只注册一个拖动事件即可，否则拖动时没有效果
//        dragstart: dragEventFactory('bpmn:IntermediateThrowEvent')
//      }
//    },
       'create.timing': {
        title: '我是自定义节点-ych网关',    // 鼠标悬浮到节点上显示的文字
        group: 'model',
        className: 'icon-custom bpmn-icon-timing',   // 样式名
        action: {      // 操作该节点时会触发的事件，此时只注册一个拖动事件即可，否则拖动时没有效果
          dragstart: dragEventFactory('bpmn:IntermediateCatchEvent')
        }
      },
      
    };
  }
}
CustomPalette.$inject = [
  'create',
  'elementFactory',
  'palette'
];