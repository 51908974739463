var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TForm", {
        ref: "refFrom1",
        attrs: {
          lableRow: "",
          autoCheck: true,
          model: _vm.htmlDatas,
          formlist: _vm.formlist,
          rowGutter: 16,
          labelPosition: "left",
        },
        scopedSlots: _vm._u([
          {
            key: "copyTarget",
            fn: function (scope) {
              return [_vm._v("\n\t\t\t抄送对象diy\n\t\t")]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }