<template>
	<!--审核节点 表单字段设置-->
	<div class="Y-tips">
		<el-dialog class='diyModel' title="表单字段设置" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="1000px" :before-close="closeMypup">
			<div >
				<div class="topPart">
					
					<div class="flxdiv btndiv" @click="changeWordsType('1')" :class="{actived:htmlDatas.isDefault}">默认1（可见不可编辑）</div>
					<div class="flxdiv ck_node" >
						<el-select @click="changeWordsType('2')" v-model="htmlDatas.checkedNode" placeholder="请选择">
						    <el-option v-for="item in nodeArrs" :key="item.nodeId" :label="item.name" :value="item.nodeId" />
					  	</el-select>
					</div>
				</div>
				<div class="keyWordsFrom">
					后期引入子淇写的表单配置组件
					
				</div>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='getFromDatas' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			nodeArrs:{
				type: Array,
				default: [],
			},
//			model: {
//				type: Object,
//				default: ()=>{
//					return {
//						wordType:'1',
//						isDefault:false,
//						checkedNode:'',
//					}
//				},
//			},
			datas: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		components: {TForm},
		data() {
			return {
//				nodeArrs:[
//					{name:'A',id:'1'},
//					{name:'B',id:'2'},
//					{name:'C',id:'3'},
//				],
				htmlDatas:{
					isDefault:true,
					checkedNode:'',
				},
	  			isHttping:false,
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			console.log('1111p接收字段datas',JSON.parse(JSON.stringify(this.datas)))
			this.getDatasById();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeWordsType(type){
				if(type=='1'){
					this.htmlDatas.isDefault=true;
				}else{
					this.htmlDatas.isDefault=false;
				}
				console.log(this.htmlDatas.isDefault)
				
				
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
//	  			for(let item of this.formlist) { //初始化赋值我的表单数据
//					this.$set(this.htmlDatas, item.keys, item.value);
//				}
	  		},
	  		changeSetName(val,it){
				//因为select选择的时候前端需要用到对应的name属性
				let _key=it.keys+'Str';
				let _val;
				it.options.map(it=>{
					if(val==it.code) _val=it.name;
				})
				if(!_val) return;
				this.$set(this.htmlDatas, _key, _val);
			},
			async getDatasById(){
				
				if(this.datas.webId){
					this.htmlDatas=JSON.parse(JSON.stringify(this.datas));
					
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
				
				
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				console.log(this.datas)
				let mId=this.datas.webId;
				if(!mId){
					mId=this.common.creadWebId();
				}
				let params={
					webId:mId,
					...this.htmlDatas,
				}
				this.$emit('close',params)
//				this.$refs.htmlFrom.resetFields();
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	margin-top: 20px;
	text-align: right;
}
.topPart{
	margin-bottom: 16px;
	display: flex;
	.flxdiv{
		line-height: 36px;
		border: 1px solid #D1D5DD;
		.el-input__inner{
			background:white;
		}
	}
	.btndiv{
		width: 172px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		cursor: pointer;
		margin-right: 16px;
	}
	.btndiv.actived{
		color: #2A61FF;
		border: 1px solid #2A61FF;
	}
}
.keyWordsFrom{
	border: 1px solid #E3E8EE;
	height: 364px;
	overflow-y: auto;
	padding: 20px;
}
</style>