<template>
	<!--抄送节点 新增抄送对象弹窗-->
	<div class="Y-tips">
		<el-dialog class='diyModel' title="添加抄送对象" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="30%" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			datas: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{},
	  			isHttping:false,
	  			formlist:[
	  				
	  				{name: '抄送类型',keys: 'copyType',value:'1',type: 'select',notClear:true,options:[
//	  					{name:'请选择',code:''},
	  					{name:'指定人',code:'1'},
	  					{name:'指定部门',code:'2'},
	  				],changMethod:this.changeSetName},
	  				{name: '抄送人',keys: 'copyPerson',value:'',type: 'select',notClear:true,options:[],isHide:false,changMethod:this.changeSetName},
	  				{name: '抄送部门',keys: 'copyDuty',value:'',type: 'select',notClear:true,options:[],isHide:true,changMethod:this.changeSetName},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			'htmlDatas.copyType': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.hideOrShowItems();
					
				}
			},
			
		},
		created() {
		},
		mounted() {
			this.$nextTick(()=>{
				this.initPersomList();
				this.initDeptList();
			})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			hideOrShowItems(){
				//通过定时方式判断当前列是否显示
				let keyArr1=['copyPerson'],keyArr2=['copyDuty'];
				let showIts=[],hideIts=[];
				if(this.htmlDatas.copyType=='1'){
					showIts=keyArr1;
					hideIts=keyArr2;
				}else{
					showIts=keyArr2;
					hideIts=keyArr1;
				}
				for(let it of this.formlist){
					if(hideIts.indexOf(it.keys)>-1){
						it.isHide=true;
					}
					if(showIts.indexOf(it.keys)>-1){
						it.isHide=false;
					}
				}
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		changeSetName(val,it){
				//因为select选择的时候前端需要用到对应的name属性
				let _key=it.keys+'Str';
				let _val;
				it.options.map(it=>{
					if(val==it.code) _val=it.name;
				})
				if(!_val) return;
				this.$set(this.htmlDatas, _key, _val);
				
			},
			
			async initPersomList(){//指定人数据
				let res=await this.ApiHttp('/organization/tenant/employee/empList');
				if(res){
					res.map(it=>{
						it.name = it.userName;
						it.code = it.userId;
					})
					this.common.insertOptions(this.formlist,'copyPerson',res|| []);
				}
			},
			
			async initDeptList(projectId=1){//部门数据
				let res=await this.ApiHttp('/base/tenant/project/findDeptListByProject?projectId='+projectId);
				if(res){
					res.map(it=>{
						it.name = it.deptName;
						it.code = it.deptId;
					})
					this.common.insertOptions(this.formlist,'copyDuty',res|| []);
				}
			},
				
			async getDatasById(){},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={...this.htmlDatas,}
				if(params.copyType=='1') params.name=params.copyPersonStr
				if(params.copyType=='2') params.name=params.copyDutyStr
				this.$emit('close',params)
				this.$refs.htmlFrom.resetFields();
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>