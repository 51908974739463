<template>
	<div class="rtConfig">
		<div class="tabs_warp">
	  		<div class="it_tap" v-for="(it,dex) in tabsArr" :key='dex' :class="{'actived':activeTabs==it.val}" v-if="!it.hide">
	  			<div  class="it_n" @click='setTabChange(it)'>{{ it.name }} <div class="xbtm"></div></div>
	  		</div>
	  	</div>	
		<div class="formWarp" ref="propertyPanel">
			<div v-if="!isClickNode">
				<!--没有选中节点的时候  是流程配置-->
				<div v-if="activeTabs==1">
					<!--<component :is="formName" :formInfo='webForms' @upvals='fromValsChange'></component>-->
					
					<flowWhole :model='flowCofigs.basics'></flowWhole>
				</div>
				<div v-else>
					<listenSet :formInfo='flowCofigs.listen'  @upvals='fromValsChange'></listenSet>
				</div>
			</div>
			<div v-if="isClickNode" :class="getNodeYype">
				<div v-if="activeTabs==1">
					<el-form :model="webForms" label-width="0" size="small" label-position='left' >
						<el-form-item label="" style='margin-right: 0;'>
							<div class="itFormlabel"><span class="diyXx">*</span> 节点名称</div>
							<el-input v-model="webForms.name" @input="nameChange" style="width: 100%;"></el-input>
						</el-form-item>
						<!--<el-form-item label="" style='margin-right: 0;'>
							<div class="itFormlabel"><span class="diyXx">*</span> 节点key1</div>
							<el-input v-model="webForms.webkey" style="width: 100%;"></el-input>
						</el-form-item>-->
						<!--<el-form-item label="节点颜色">
							<el-color-picker v-model="webForms.color" @active-change="colorChange"></el-color-picker>
						</el-form-item>-->
						<!--<el-form-item label="节点人员" v-if="userTask">
							<el-select v-model="webForms.userType" placeholder="请选择" @change="typeChange" style="width: 180px;">
								<el-option value="assignee" label="指定人员"></el-option>
								<el-option value="candidateUsers" label="候选人员"></el-option>
								<el-option value="candidateGroups" label="角色/岗位"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="指定人员" v-if="userTask && webForms.userType === 'assignee'">
							<el-select v-model="webForms.assignee" placeholder="请选择" key="1" @change="(value) => addUser({assignee: value})">
								<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="候选人员" v-else-if="userTask && webForms.userType === 'candidateUsers'">
							<el-select v-model="webForms.candidateUsers" placeholder="请选择" key="2" multiple @change="(value) => addUser({candidateUsers: value.join(',') || value})">
								<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="角色/岗位" v-else-if="userTask && webForms.userType === 'candidateGroups'">
							<el-select v-model="webForms.candidateGroups" placeholder="请选择" @change="(value) => addUser({candidateGroups: value})">
								<el-option v-for="item in roles" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="分支条件" v-if="sequenceFlow">
							<el-select v-model="webForms.user" placeholder="分支允许添加条件">
								<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>-->
					</el-form>
						<component :is="formName" :formInfo='webForms' @upvals='fromValsChange'></component>
					</div>
					<div v-if="activeTabs==2">
						<listenSet :formInfo='webForms'  @upvals='fromValsChange'></listenSet>
					</div>
					<div v-if="activeTabs==3">
						<userTaskExamine :formInfo='webForms' @upvals='fromValsChange' />
					</div>
					<div v-if="activeTabs==4">
						<lineExpression :formInfo='webForms' @upvals='fromValsChange' />
					</div>
			</div>
		</div>
	</div>
</template>

<script>
	import listenSet from './fromTypes/listenSet.vue';//监听设置
	import flowWhole from './fromTypes/flowWhole.vue';//流程配置
	import copyNode from './fromTypes/copyNode.vue';//抄送节点
	import startNode from './fromTypes/startNode.vue';//开始节点
	import endNode from './fromTypes/startNode.vue';//结束节点
	import userTaskNode from './fromTypes/userTaskNode.vue';//审核人设置
	import userTaskExamine from './fromTypes/userTaskExamine.vue';//审核节点
	
	import timingNode from './fromTypes/timingNode.vue';//审核节点
	import lineNode from './fromTypes/lineNode.vue';//线节点
	import lineExpression from './fromTypes/lineExpression.vue';//线节点-表达式
	export default {
		components: { listenSet,flowWhole,copyNode,startNode,endNode,userTaskNode,userTaskExamine,timingNode,lineNode,lineExpression},
		props: {
			modeler: {
				type: Object,
				required: true
			}
		},
		computed: {
			userTask() {
				if(!this.element) {
					return;
				}
				return this.element.type === "bpmn:UserTask";
			},
			sequenceFlow() {
				if(!this.element) {
					return;
				}
				return this.element.type === "bpmn:SequenceFlow";
			},
			getNodeYype(){
				if(!this.element) {
					return;
				}
				const typeStr=this.element.type.replace('bpmn:','');
				this.formName=this.bpmnArrs[typeStr];
				console.log('----------ffffname----------',typeStr,this.formName)
				return typeStr;
			},
		},
		data() {
			return {
				tabsArr:[],
				tabsDefault:[
					{name:'基本设置',val:'1'},
					{name:'监听设置',val:'2',hide:false},
					{name:'审核人设置',val:'3',hide:true},
					{name:'表达式',val:'4',hide:true},
				],
				activeTabs:'1',
				formName:'flowWhole',
				bpmnArrs:{
					'ReceiveTask':'copyNode',//抄送节点
					'StartEvent':'satartNode',//开始节点
					'StartEvent':'endNode',//结束节点
					'Task':'userTaskNode',//审核节点
					'UserTask':'userTaskNode',//审核节点
					'IntermediateCatchEvent':'timingNode',
					'IntermediateThrowEvent':'timingNode',
					'SequenceFlow':'lineNode',//线节点
				},
				isClickNode: false, //是否有选中节点
				flowCofigs :{
					basics:{},//基本设置
					listen:[],//监听设置
				},
				webForms: {
					id: "",
					name: "",
					color: null,
					key1: 'hellow',
				},
				element: {},
				users: [{
						value: "zhangsan",
						label: "张三"
					},
					{
						value: "lisi",
						label: "李四"
					},
					{
						value: "wangwu",
						label: "王五"
					}
				],
				roles: [{
						value: "manager",
						label: "经理"
					},
					{
						value: "personnel",
						label: "人事"
					},
					{
						value: "charge",
						label: "主管"
					}	
				]
			};
		},
		mounted() {
			this.initTabsData();
			this.handleModeler();
			
			let flowStr = localStorage.getItem('flowDatas');
			if(flowStr){
				const { flowCofigs} =JSON.parse(flowStr);
				this.flowCofigs=flowCofigs
			}
			
		},
		watch:{
			formName: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.initTabsData();
				}
			},
			
		},
		methods: {
			setTabChange(it){
				this.activeTabs=it.val;
//				this.$emit('saveWebData')
			},
			async getNowNodes(){
				let nodeArrs=await this.$parent.$parent.nowBpmnNodeToDatas();
				return nodeArrs;
			},
			fromValsChange(val){
				console.log('from----Change',this.isClickNode,JSON.parse(JSON.stringify(val)))
				//监听对应的节点配置变更时候  更新flow节点信息。
				if(this.isClickNode){
					//如果有选中节点的话 代表节点监听设置  更新数据
					this.webForms={
						...this.webForms,
						...JSON.parse(JSON.stringify(val)),
					};
					const modeling = this.modeler.get("modeling");
					let valStr=JSON.stringify(this.webForms);
					modeling.updateProperties(this.element,{webDatas:valStr});
					if(val.nodeName){
						modeling.updateLabel(this.element, val.nodeName);
					}
				}else{
					//没有的话 代表的是流程监听设置
					this.flowCofigs.listen={
						...this.webForms,
						...JSON.parse(JSON.stringify(val)),
					};
					
					console.log('----流程监听设置',JSON.parse(JSON.stringify(this.flowCofigs)))
				}
				
				
				
			},
			initTabsData(){
				let Arrs=JSON.parse(JSON.stringify(this.tabsDefault));
				if(this.isClickNode){
					if(this.formName=='lineNode'){
						Arrs=[
							{name:'基本设置',val:'1'},
							{name:'表达式',val:'4',hide:false},
						]
					}
					if(this.formName=='userTaskNode'){
						Arrs=[
							{name:'基本设置',val:'1'},
							{name:'审核人设置',val:'3',hide:false},
							{name:'监听设置',val:'2',hide:false},
						]
					}
				}
				console.log('*************tabsArr',this.formName,JSON.parse(JSON.stringify(Arrs)))
				this.tabsArr=Arrs;
				
			},
			
			handleModeler() {
				// 监听节点选择变化
				this.modeler.on("selection.changed", e => {
					const element = e.newSelection[0];
					this.element = element;
					this.activeTabs='1';//节点发生变化  右侧视图切回基本设置
					this.webForms={};
//					console.log('1111111节点选择变化',this.element);
					if(!element) {
						this.formName='flowWhole';
						this.isClickNode = false;
						return;

					} else {
						this.webForms = {
							...element.businessObject,
							...element.businessObject.$attrs
						};
						if(!this.webForms.nodeCode){
							let tNum=new Date().getTime();
							let _key=element.type.replace('bpmn:','')+tNum.toString();
//							this.webForms={...this.webForms,...{webkey:_key,nodeCode:'hellow'}}
							this.webForms={...this.webForms,...{nodeCode:_key}}
						}
						console.log('222222element.webForms',JSON.parse(JSON.stringify(this.webForms)))
						const webDatas=element.businessObject.$attrs.webDatas;
						if(webDatas){
							this.webForms={
								...this.webForms,
								...JSON.parse(webDatas),//取出保存扩展的数据[字符串]，转成对象解构赋值，用于回显对应的表单信息
							}
						}
						if(this.webForms.userType === "candidateUsers") {
							this.webForms["candidateUsers"] = this.webForms["candidateUsers"].split(",") || [];
						}
						this.isClickNode = true;
//						console.log('222222element.changed',JSON.parse(JSON.stringify(this.webForms)))
					}
					
				});

//				//  监听节点属性变化
				this.modeler.on("element.changed", e => {
					const {
						element
					} = e;
					if(!element) return;
					//  新增节点需要更新回属性面板
					if(element.id === this.webForms.id) {
						
						this.webForms.name = element.businessObject.name;
						this.webForms = { ...this.webForms
						};
//						console.log('333333333element.changed',JSON.parse(JSON.stringify(this.webForms)))
					}
				});
				
				const selection = this.modeler.get('selection');
				if(selection && selection.get().length > 0) {
					// 有选中的节点
					console.log('当前有选中的节点');
				} else {
					// 没有选中的节点
					console.log('当前没有选中的节点');
				}
				if(this.webForms.name === '') {
					//              this.$message.warning('请选择流程节点！');
					return
				}
			},
			// 属性面板名称，更新回流程节点
			nameChange(name) {
				const modeling = this.modeler.get("modeling");
				modeling.updateLabel(this.element, name);
			},
			// 属性面板颜色，更新回流程节点
			colorChange(color) {
				const modeling = this.modeler.get("modeling");
				modeling.setColor(this.element, {
					fill: null,
					stroke: color
				});
				modeling.updateProperties(this.element, {
					color: color
				});
			},
			// 任务节点配置人员
			addUser(properties) {
				this.updateProperties(
					Object.assign(properties, {
						userType: Object.keys(properties)[0]
					})
				);
			},
			// 切换人员类型
			typeChange() {
				const types = ["assignee", "candidateUsers", "candidateGroups"];
				types.forEach(type => {
					delete this.element.businessObject.$attrs[type];
					delete this.webForms[type];
				});
			},
			setPropertiesKey(keys) {
				
				let info={};
				info[keys]=this.webForms[keys];
				const modeling = this.modeler.get("modeling");
				modeling.updateProperties(this.element,info);
			},

			// 在这里我们封装一个通用的更新节点属性的方法
			updateProperties(properties) {
				const modeling = this.modeler.get("modeling");
				modeling.updateProperties(this.element, properties);
			}
		}
	};
</script>

<style scoped>
	.rtConfig{
		height: 100%;
	}
	.formWarp {
		/*border-left: 1px solid #cccccc;*/
		padding:16px 18px;
		width: 100%;
		height: calc(100% - 50px);
		position: relative;
		top: 0;
		overflow-y: auto;
	}
	.itFormlabel{
		color: #252D3D;
		font-size: 14px;
		font-weight: bold;
		.diyXx{
			color: #F56C6C;
		}
	}
	.tabs_warp{
		line-height: 48px;
		display: flex;
		border-bottom: 1px solid #eee;
		.it_tap{
			padding:0px 10px;
			line-height: 48px;
			color:#5D687C;
			cursor: pointer;
			flex: 8;
			max-width: 100px;
			text-align: center;
	        .it_n{
	        	font-size: 14px;
	        	font-weight: bold;
	        	position: relative;
	        	.xbtm{
	        		height: 2px;
	        		width: 20px;
	        		position: absolute;
	        		bottom: -1px;
	        		left: 50%;
	        		margin-left: -10px;
	        		
	        	}
	        }
		}
		.it_tap:hover{
			color: #2a61ff !important;
		}
		.actived.it_tap{
			color: #252D3D;
			.xbtm{
				background: #1A4CEC;
			}
			
		}
	}
</style>